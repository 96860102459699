import React from 'react';
import { Box, Image } from '@chakra-ui/react';

interface HoverImageProps {
    image1: string,
    image2: string
}

const HoverImage: React.FC<HoverImageProps> = ({image1, image2}) => {
  return (
    <Box
      position="relative"
      width="500px"
      height="350px"
      borderRadius="8px"
      overflow="hidden"
    >
      <Image
        src={image1}
        alt="Michael's Photo"
        position="absolute"
        top={0}
        left={0}
        width="100%"
        height="100%"
        objectFit="cover"
        transition="opacity 0.3s"
        opacity={1}
        _hover={{ opacity: 0 }}
      />
      <Image
        src={image2}
        alt="Alternative Photo"
        position="absolute"
        top={0}
        left={0}
        width="100%"
        height="100%"
        objectFit="cover"
        transition="opacity 0.3s"
        opacity={0}
        _hover={{ opacity: 1 }}
      />
    </Box>
  );
};

export default HoverImage;
