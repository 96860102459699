import { Box } from "@chakra-ui/react";
import image1 from "../../images/LandingPage1.jpeg";
import image2 from "../../images/LandingPage2.jpeg";
import image3 from "../../images/LandingPage3.jpeg";
import LandingPageImage from "./LandingPageImage";
import { dataImage1, dataImage2, dataImage3 } from "../../model/imageData";
import { useHandleImageClick } from "../../hooks/useHandleImageClick";

import click from "../../images/clickLottieAnimation.json";
import Lottie from "lottie-react";
import { useState } from "react";

const LandingPageImageSection: React.FC = () => {
    const [clicked, setClicked] = useState<boolean>(false);
    const handleImageClick = useHandleImageClick(setClicked);

    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            position="relative"
            p={4}
        >
            <LandingPageImage
                src={image1}
                top={"5%"}
                left={"15%"}
                data={dataImage1}
                index={1}
                onClick={handleImageClick}
            />
            <LandingPageImage
                src={image2}
                top={"35%"}
                left={"45%"}
                data={dataImage2}
                index={2}
                onClick={handleImageClick}
            />
            <LandingPageImage
                src={image3}
                top={"65%"}
                left={"15%"}
                data={dataImage3}
                index={3}
                onClick={handleImageClick}
            />
            {!clicked && (
                <Box
                    position="absolute"
                    top="50%"
                    left="65%"
                    width="100%"
                    height="100%"
                    pointerEvents="none"
                >
                    <Lottie
                        animationData={click}
                        style={{ height: 300, width: 300 }}
                    />
                </Box>
            )}
        </Box>
    );
};

export default LandingPageImageSection;
