import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { ProgressModel } from "../landingPageSlice";
import { useSelector } from "react-redux";
import { Progress } from "../../types/progress";

export const selectItems = (state: RootState) => state.landingPage.items;
export const selectSelectedImage = (state: RootState) =>
    state.landingPage.selectedImage;
export const selectImagePosition = (state: RootState) =>
    state.landingPage.imagePosition;
export const selectSelectedSubject = (state: RootState) =>
    state.landingPage.selectedSubject;
export const selectStudents = (state: RootState) => state.landingPage.students;
export const selectProgress = (state: RootState) => state.landingPage.progress;

const progressSymbols = {
    [Progress.Unplanned]: "",
    [Progress.Planned]: " ",
    [Progress.GaveALesson]: "∕",
    [Progress.WorkingOnIt]: "Λ",
    [Progress.Mastered]: "△",
};

export const makeSelectProgressSymbol = () =>
    createSelector(
        [
            selectProgress,
            (_, student: string) => student,
            (_, __, work: string) => work,
        ],
        (progress: ProgressModel[], student: string, work: string) => {
            const progressModel = progress.find(
                (p) => p.student === student && p.work === work
            );
            return progressModel
                ? progressSymbols[progressModel.progress as Progress]
                : null;
        }
    );

export const useProgressSymbol = (student: string, work: string) => {
    const selectProgressSymbol = makeSelectProgressSymbol();
    return useSelector((state: RootState) =>
        selectProgressSymbol(state, student, work)
    );
};
