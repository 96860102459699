import { ProgressModel } from "../store/landingPageSlice";
import { Progress } from "../types/progress";

export const parseUpdateIntoProgress = (
    update: string
): ProgressModel[] | false => {
    const patterns = [
        { regex: /^(.*?) is working on (.*)$/, progress: " is working on " },
        {
            regex: /^(.*?) was given a lesson on (.*)$/,
            progress: " was given a lesson on ",
        },
        { regex: /^(.*?) mastered (.*)$/, progress: " mastered " },
    ];

    for (const { regex, progress } of patterns) {
        const match = update.match(regex);
        if (match) {
            const progressModels: ProgressModel[] = []
            const names = match[1].split("&");
            names.forEach((name) => {
                progressModels.push({
                    student: name.trim(),
                    progress: progress as Progress,
                    work: match[2],
                });
            })
            return progressModels as ProgressModel[];
        }
    }
    return false;
};
