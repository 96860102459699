export const lineStyle = {
    position: "relative",
    textAlign: "center",
    "&::before": {
        content: '""',
        position: "absolute",
        top: 0,
        bottom: 0,
        left: "50%",
        width: "2px",
        backgroundColor: "black",
        transform: "translateX(-50%)",
        zIndex: 0,
    },
};

export const topLineStyle = {
    position: "relative",
    textAlign: "center",
    "&::before": {
        content: '""',
        position: "absolute",
        top: "50%",
        bottom: 0,
        left: "50%",
        width: "2px",
        height: "50%",
        backgroundColor: "black",
        transform: "translateX(-50%)",
        zIndex: 0,
    },
};

export const bottomLineStyle = {
    position: "relative",
    textAlign: "center",
    "&::before": {
        content: '""',
        position: "absolute",
        top: 0,
        bottom: "50%",
        left: "50%",
        width: "2px",
        height: "50%",
        backgroundColor: "black",
        transform: "translateX(-50%)",
        zIndex: 0,
    },
};

export const circleStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "24px",
    height: "24px",
    borderRadius: "50%",
    border: "1px solid black",
    margin: "0 auto", // Center the circle horizontally
    zIndex: 1,
    position: "relative",
};

export const unPlannedCircleStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "8px",
    height: "8px",
    borderRadius: "50%",
    backgroundColor: "black",
    border: "1px solid black",
    margin: "8px auto",
    zIndex: 1,
    position: "relative",
};

