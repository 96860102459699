import { Box, Flex, Heading, Image, Text } from "@chakra-ui/react";
import Lottie from "lottie-react";

import curlyarrow from "../images/curlyArrowLottieAnimation.json";
import mariaWithKids from "../images/Mariawithkids.jpeg";
import TryItOutButton from "./buttons/TryItOutButton";

const CatchphraseSection = () => {
    return (
        
            <Box bg="teal.100" color="teal.900" p={16} pl={24} height="750px">
                <Flex align="center" height="100%">
                    <Box flex="1">
                        <div style={{ height: 200, width: 200 }}> </div>
                        <Heading as="h2" size="xl" fontSize="5xl" display="block">
                            Observe like Maria, 
                            <Text
                                as="i"
                                fontSize="9xl"
                                fontFamily="'Shadows Into Light', cursive"
                                display="block"
                                position={"relative"}
                                top={-10}
                            >
                                on paper
                            </Text>
                        </Heading>
                        <Text fontSize="xl" mt={4}>
                            Snap a photo. Approve changes. Goodbye screen guilt
                            👋
                        </Text>
                        <Flex>
                            <Box mt={8}>
                                <TryItOutButton />
                            </Box>
                            <Lottie
                                animationData={curlyarrow}
                                style={{ height: 200, width: 200 }}
                            />
                        </Flex>
                    </Box>
                    <Box flex="1" textAlign="center">
                        <Image
                            src={mariaWithKids}
                            alt="An image of Montessori observations"
                            width="700px"
                            height="700px"
                            objectFit="cover"
                            borderRadius="8px"
                            border="solid 4px white"
                        />
                    </Box>
                </Flex>
            </Box>
    );
};

export default CatchphraseSection;
