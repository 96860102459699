import { ProgressModel } from "../store/landingPageSlice";
import { Progress } from "../types/progress";

export const startingProgress: ProgressModel[] = [
    { student: "Sydney", progress: Progress.WorkingOnIt, work: "Button Frame" },
    { student: "Sydney", progress: Progress.GaveALesson, work: "Snap Frame" },
    { student: "Aidan", progress: Progress.Mastered, work: "Button Frame" },
    { student: "Aidan", progress: Progress.WorkingOnIt, work: "Snap Frame" },
    { student: "Aidan", progress: Progress.Planned, work: "Weaving" },
    { student: "Frank", progress: Progress.Mastered, work: "Button Frame" },
    { student: "Frank", progress: Progress.WorkingOnIt, work: "Snap Frame" },
    { student: "Frank", progress: Progress.GaveALesson, work: "Weaving" },
    { student: "Eloise", progress: Progress.Planned, work: "Button Frame" },
    { student: "Sydney", progress: Progress.WorkingOnIt, work: "Knobbed Cylinders" },
    { student: "Eloise", progress: Progress.WorkingOnIt, work: "Knobbed Cylinders" },
    { student: "Sydney", progress: Progress.WorkingOnIt, work: "Pink Tower" },
    { student: "Eloise", progress: Progress.GaveALesson, work: "Pink Tower" },
    { student: "Sydney", progress: Progress.GaveALesson, work: "Broad Stair" },
    { student: "Aidan", progress: Progress.WorkingOnIt, work: "Knobbed Cylinders" },
    { student: "Frank", progress: Progress.Mastered, work: "Knobbed Cylinders" },
    { student: "Frank", progress: Progress.Mastered, work: "Pink Tower" },
    { student: "Frank", progress: Progress.Mastered, work: "Broad Stair" },
    { student: "Aidan", progress: Progress.GaveALesson, work: "Pink Tower" },
    { student: "Aidan", progress: Progress.Planned, work: "Broad Stair" },
    { student: "Aidan", progress: Progress.Mastered, work: "Sandpaper Numerals" },
    { student: "Sydney", progress: Progress.Mastered, work: "Sandpaper Numerals" },
    { student: "Sydney", progress: Progress.Mastered, work: "Number Rods" },
    { student: "Sydney", progress: Progress.GaveALesson, work: "Spindle Boxes" },
    { student: "Eloise", progress: Progress.WorkingOnIt, work: "Sandpaper Numerals" },
    { student: "Eloise", progress: Progress.Planned, work: "Number Rods" },
    { student: "Frank", progress: Progress.GaveALesson, work: "Sandpaper Numerals" },
    { student: "Frank", progress: Progress.Planned, work: "Number Rods" },
    { student: "Aidan", progress: Progress.WorkingOnIt, work: "Number Rods" },
    { student: "Aidan", progress: Progress.WorkingOnIt, work: "Metal Insets" },
    { student: "Aidan", progress: Progress.WorkingOnIt, work: "SPL #2" },
    { student: "Aidan", progress: Progress.GaveALesson, work: "Movable Alphabet" },
    { student: "Frank", progress: Progress.Mastered, work: "Metal Insets" },
    { student: "Frank", progress: Progress.Mastered, work: "SPL #2" },
    { student: "Frank", progress: Progress.WorkingOnIt, work: "Movable Alphabet" },
    { student: "Sydney", progress: Progress.WorkingOnIt, work: "Metal Insets" },
    { student: "Sydney", progress: Progress.GaveALesson, work: "SPL #2" },
    { student: "Sydney", progress: Progress.Planned, work: "Movable Alphabet" },
    { student: "Eloise", progress: Progress.GaveALesson, work: "Metal Insets" },
    { student: "Eloise", progress: Progress.Planned, work: "SPL #2" },
    { student: "Eloise", progress: Progress.Planned, work: "Continent Map" },
    { student: "Sydney", progress: Progress.WorkingOnIt, work: "Continent Map" },
    { student: "Aidan", progress: Progress.Mastered, work: "Continent Map" },
    { student: "Frank", progress: Progress.Mastered, work: "Continent Map" },
    { student: "Aidan", progress: Progress.Planned, work: "North America Map" },
    { student: "Frank", progress: Progress.GaveALesson, work: "North America Map" },
];
