import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
    fontSizes: {
        "9xl": "7rem",
        "10xl": "8rem",
        "11xl": "9rem",
        "12xl": "10rem",
        // Add more custom sizes if needed
    },
});

export default theme;
