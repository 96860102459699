import { Subject } from "../model/subjectWorksData"
import { ProgressSymbol } from "../types/progress";
type ColorMap = {
    [key in ProgressSymbol]: string | { [key in Subject]: string };
};

export const getColorFromSubjectAndProgress = (subject: Subject, progress: string) => {
    const colorMap: ColorMap = {
        "": "white",
        " ": "white",
        "∕": {
            "Language": "#ffd1e0", // even lighter pastel red
            "Sensorial": "#ffffcc", // even lighter pastel yellow
            "Math": "#d1fde1", // even lighter pastel green
            "Geography": "#e6d0ff", // even lighter pastel purple
            "Practical Life": "#b3e6ff" // even lighter pastel blue
        },
        "Λ": {
            "Language": "#ffadd6", // Lighter pink
            "Sensorial": "#ffff80", // Lighter yellow
            "Math": "#96f0a1", // Lighter green
            "Geography": "#b99aff", // Lighter purple
            "Practical Life": "#80d4ff" // Lighter blue
        },
        "△": {
            "Language": "#ff7ebf", // Medium pink
            "Sensorial": "#ffff40", // Medium yellow
            "Math": "#61e06b", // Medium green
            "Geography": "#9933ff", // Medium purple
            "Practical Life": "#40a4ff" // Medium blue
        }
    };
    
    const color = colorMap[progress as ProgressSymbol];
    if (typeof color === "string") {
        return color;
    }
    return color[subject] || "white";
};