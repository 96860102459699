import { Box, Td } from "@chakra-ui/react";
import {
    selectSelectedSubject,
    useProgressSymbol,
} from "../../store/selectors/landingPageSelectors";
import {
    bottomLineStyle,
    circleStyle,
    lineStyle,
    topLineStyle,
    unPlannedCircleStyle,
} from "../../styles/LandingPageSoftwareSection";
import { useSelector } from "react-redux";
import { getColorFromSubjectAndProgress } from "../../functions/getColorFromSubjectAndProgress";

interface WorkRopeBeadProps {
    index: number;
    student: string;
    work: string;
    subjectWorksCount: number;
}

const WorkRopeBead: React.FC<WorkRopeBeadProps> = ({
    index,
    student,
    work,
    subjectWorksCount,
}) => {
    const symbol = useProgressSymbol(student, work);
    const chooseLineStyle = (index: number) => {
        if (index == 0) {
            return topLineStyle;
        } else if (index == subjectWorksCount) {
            return bottomLineStyle;
        } else {
            return lineStyle;
        }
    };
    const chooseCircleStyle = () => {
        if (symbol === null || symbol === "") {
            return unPlannedCircleStyle;
        } else {
            return circleStyle;
        }
    };
    console.log(student, work, symbol)
    const subject = useSelector(selectSelectedSubject);
    const color = getColorFromSubjectAndProgress(subject, symbol || "");

    return (
        <Td sx={chooseLineStyle(index)}>
            <Box sx={chooseCircleStyle()} backgroundColor={color}>
                {symbol}
            </Box>
        </Td>
    );
};

export default WorkRopeBead;
