import { Button } from "@chakra-ui/react";
import { Link as ScrollLink } from "react-scroll";

const AboutButton = () => {
    return (
        <Button
            colorScheme="teal"
            variant="ghost"
            color="white"
            fontWeight="bold"
            _hover={{ color: "teal.500", bg: "white" }}
            as={ScrollLink}
            to="about-section"
            smooth={true}
            duration={500}
            sx={{ cursor: 'pointer' }}
        >
            About
        </Button>
    );
};

export default AboutButton;
