import { Tbody, Tr, Td } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { getWorksForSubject } from "../../model/subjectWorksData";
import {
    selectStudents,
    selectSelectedSubject,
} from "../../store/selectors/landingPageSelectors";
import WorkRopeBead from "./WorkRopeBead";

const WorkRopeTBody = () => {
    const students = useSelector(selectStudents);
    const subject = useSelector(selectSelectedSubject);
    const subjectWorks = getWorksForSubject(subject);

    return (
        <Tbody>
            {subjectWorks.map((work, index) => {
                return (
                    <Tr sx={{ "th, td": { borderBottom: "none" } }}>
                        <Td fontWeight="bold">{work}</Td>
                        {students.map((student) => (
                            <WorkRopeBead
                                index={index}
                                work={work}
                                student={student}
                                subjectWorksCount={subjectWorks.length - 1}
                            />
                        ))}
                    </Tr>
                );
            })}
        </Tbody>
    );
};

export default WorkRopeTBody;
