import React from "react";
import { Box, ListItem, Text, HStack, IconButton } from "@chakra-ui/react";
import { motion } from "framer-motion";
import { FaCheck, FaTrash } from "react-icons/fa";
import { ImageDataModel } from "../../model/imageData";
import { useUpdateHandlers } from "../../hooks/useUpdateListItemHandlers";

const MotionBox = motion(Box);

interface UpdateItemProps {
    item: ImageDataModel;
    key: string;
}

const UpdateItem: React.FC<UpdateItemProps> = ({ item }) => {
    const { handleApprove, handleRemove } = useUpdateHandlers(item);

    return (
        <MotionBox
            initial={{ scale: 0, opacity: 0, height: 0 }}
            animate={{
                scale: [0.5, 1.1, 1],
                opacity: [0, 0.4, 1],
                height: "auto",
            }}
            exit={{ scale: 0, opacity: 0 }}
            transition={{ duration: 0.4 }}
            layout // Enable layout animations
        >
            <ListItem
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                p={2}
                bg="white"
                borderRadius="md"
                boxShadow="md"
                width="100%"
            >
                <Text>{item.update}</Text>
                <HStack spacing={2} ml={4}>
                    <IconButton
                        size="sm"
                        colorScheme="green"
                        icon={<FaCheck />}
                        aria-label="Approve"
                        onClick={handleApprove}
                    />
                    <IconButton
                        size="sm"
                        colorScheme="red"
                        icon={<FaTrash />}
                        aria-label="Delete"
                        onClick={handleRemove}
                    />
                </HStack>
            </ListItem>
        </MotionBox>
    );
};

export default UpdateItem;
