import PDFViewer from "../components/PDFViewer";

function DataPrivacyPolicy() {
    return (
        <div style={{ height: "100vh" }}>
            <PDFViewer />
        </div>
    );
}

export default DataPrivacyPolicy;
