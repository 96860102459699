import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface UserModel {
  id: string | null;
  email: string | null;
}

interface UserState {
  user: UserModel;
}

const initialState: UserState = {
  user: {
    id: null,
    email: null,
  },
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<UserModel>) => {
      state.user.id = action.payload.id;
      state.user.email = action.payload.email;
    },
    logoutUser: (state) => {
      state.user = initialState.user;
    },
  },
});

export const { setUser, logoutUser } = userSlice.actions;

export default userSlice.reducer;
