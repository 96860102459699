export type Subject = "Practical Life" | "Sensorial" | "Math" | "Language" | "Geography";

type SubjectWorksData = {
    [key in Subject]?: string[];
}[];

export const subjectWorksData: SubjectWorksData = [
    { "Practical Life": ["Button Frame", "Snap Frame", "Weaving"] },
    { Sensorial: ["Knobbed Cylinders", "Pink Tower", "Broad Stair"] },
    { Math: ["Sandpaper Numerals", "Number Rods", "Spindle Boxes"] },
    { Language: ["Metal Insets", "SPL #2", "Movable Alphabet"] },
    { Geography: ["Continent Map", "North America Map", "South America Map"] },
];

export function getWorksForSubject(subject: Subject): string[] {
    const subjectData = subjectWorksData.find(data => Object.keys(data)[0] === subject);
    return subjectData ? subjectData[subject] || [] : [];
}
