import {
    Box,
    Table,
    TableCaption,
    TableContainer,
    Th,
    Thead,
    Tr,
} from "@chakra-ui/react";
import MyDataTabs from "./MyDataTabs";
import { useSelector } from "react-redux";
import WorkRopeTBody from "./WorkRopeTBody";
import { selectStudents } from "../../store/selectors/landingPageSelectors";

const LandingPageSoftwareSection = () => {
    const students = useSelector(selectStudents);

    return (
        <Box display="flex" justifyContent="center" alignItems="center" p={4}>
            <Box id="software-snapshot">
                <MyDataTabs
                    data={[
                        "Practical Life",
                        "Sensorial",
                        "Math",
                        "Language",
                        "Geography",
                    ]}
                />
                <br />
                <TableContainer>
                    <Table variant="simple" minWidth={"625px"}>
                        <TableCaption>
                            A snapshot of the work ropes
                        </TableCaption>
                        <Thead>
                            <Tr>
                                <Th fontWeight="bold">Works</Th>
                                {students.map((student) => (
                                    <Th>{student}</Th>
                                ))}
                            </Tr>
                        </Thead>
                        <WorkRopeTBody />
                    </Table>
                </TableContainer>
            </Box>
        </Box>
    );
};

export default LandingPageSoftwareSection;
