// src/pages/MainPage.tsx
import React from "react";
import { Box, Grid, Flex, Text } from "@chakra-ui/react";

import LandingPageImageSection from "../components/LandingPageSoftwareSnapshot/LandingPageImageSection";
import LandingPageUpdatesSection from "../components/LandingPageSoftwareSnapshot/LandingPageUpdatesSection";
import LandingPageSoftwareSection from "../components/LandingPageSoftwareSnapshot/LandingPageSoftwareSection";

import LandingPageHeader from "../components/LandingPageHeader";
import CatchphraseSection from "../components/LandingPageCatchphraseSection";
import CalendlySection from "../components/LandingPageCalendlySection";
import { Element } from "react-scroll";
import AboutSection from "../components/LandingPageAboutSection";
import { Link } from "react-router-dom";

const MainPage: React.FC = () => {
    return (
        <Flex direction="column" height="305vh" width="100vw">
            <LandingPageHeader />

            <CatchphraseSection />

            {/* Main Content Section */}
            <Element name="tryitout-section"></Element>
            <Grid
                templateColumns="2fr 1fr 2fr"
                flex="1"
                gap={4}
                p={4}
                bg="gray.50"
            >
                <LandingPageImageSection />
                <LandingPageUpdatesSection />
                <LandingPageSoftwareSection />
            </Grid>

            <CalendlySection />

            <AboutSection />

            {/* Footer Section */}
            <Box as="footer" bg="teal.500" color="white" p={4}>
                <Text>© 2024 Nakia's Notebook</Text>
                <Link to="/privacy-policy">Privacy Policy</Link>
            </Box>
        </Flex>
    );
};

export default MainPage;
