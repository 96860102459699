import { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import {
    selectImagePosition,
    selectItems,
} from "../store/selectors/landingPageSelectors";

export const useImagePosition = () => {
    const items = useSelector(selectItems);
    const imagePosition = useSelector(selectImagePosition);
    const [initialPosition, setInitialPosition] = useState<{
        top: number;
        left: number;
    } | null>(null);
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (imagePosition && containerRef.current) {
            const containerRect = containerRef.current.getBoundingClientRect();
            setInitialPosition({
                top: imagePosition.top - containerRect.top,
                left: imagePosition.left - containerRect.left,
            });
        }
    }, [imagePosition]);

    return { items, initialPosition, containerRef };
};
