import { useDispatch, useSelector } from "react-redux";
import { addProgress, removeItem, setSelectedSubject } from "../store/landingPageSlice";
import { parseUpdateIntoProgress } from "../functions/parseUpdateIntoProgress";
import { Subject } from "../model/subjectWorksData";
import { selectSelectedSubject } from "../store/selectors/landingPageSelectors";
import { ImageDataModel } from "../model/imageData";

export const useUpdateHandlers = (item: ImageDataModel) => {
    const dispatch = useDispatch();
    const subject = useSelector(selectSelectedSubject);

    const handleApprove = () => {
        const progresses = parseUpdateIntoProgress(item.update);
        if (progresses) {
            if (subject !== item.subject) {
                dispatch(setSelectedSubject(item.subject as Subject));
                setTimeout(() => {
                    for (let i = 0; i < progresses.length; i++) {
                        dispatch(addProgress(progresses[i]));
                    }
                    dispatch(removeItem(item));
                }, 250);
            } else {
                for (let i = 0; i < progresses.length; i++) {
                    dispatch(addProgress(progresses[i]));
                }
                dispatch(removeItem(item));
            }
        }
    };

    const handleRemove = () => {
        dispatch(removeItem(item));
    };

    return { handleApprove, handleRemove };
};
