import { useCallback } from "react";
import { useDispatch } from "react-redux";
import {
    setItems,
    setSelectedImage,
    setImagePosition,
} from "../store/landingPageSlice";
import { ImageDataModel } from "../model/imageData";

export const useHandleImageClick = (setClicked: React.Dispatch<React.SetStateAction<boolean>>) => {
    const dispatch = useDispatch();

    return useCallback(
        (
            index: number,
            data: ImageDataModel[],
            ref: React.RefObject<HTMLDivElement>
        ) => {
            dispatch(setItems(data));
            dispatch(setSelectedImage(index));
            setClicked(true);

            if (ref.current) {
                const rect = ref.current.getBoundingClientRect();
                dispatch(
                    setImagePosition({
                        top: rect.top, 
                        left: rect.left 
                    })
                );
            } else {
                dispatch(setImagePosition(null));
            }
        },
        [dispatch, setClicked]
    );
};
