import {
    Tabs,
    TabList,
    Tab,
    TabIndicator,
    useDisclosure,
} from "@chakra-ui/react";
import { Subject } from "../../model/subjectWorksData";
import { setSelectedSubject } from "../../store/landingPageSlice";
import { useDispatch, useSelector } from "react-redux";
import { selectSelectedSubject } from "../../store/selectors/landingPageSelectors";

interface TabData {
    label: string;
    settingValue: string;
}

interface DataTabsProps {
    data: Subject[];
}

const MyDataTabs = ({ data }: DataTabsProps) => {
    const dispatch = useDispatch();
    const subject = useSelector(selectSelectedSubject);
    const selectedIndex = data.findIndex((tab) => tab === subject);

    const handleTabChange = (index: number) => {
        dispatch(setSelectedSubject(data[index]));
    };

    return (
        <Tabs
            position="relative"
            variant="unstyled"
            onChange={handleTabChange}
            index={selectedIndex}
        >
            <TabList>
                {data.map((tab, index) => (
                    <Tab key={index}>{tab}</Tab>
                ))}
            </TabList>
            <TabIndicator
                mt="-1.5px"
                height="2px"
                bg="blue.500"
                borderRadius="1px"
            />
        </Tabs>
    );
};

export default MyDataTabs;
