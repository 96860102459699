import { Box, Flex, Heading, Text, Image } from "@chakra-ui/react";

import youngMichael from "../images/YoungMichael.jpeg";
import youngMichael2 from "../images/YoungMichael2.jpeg";
import { Element } from "react-scroll";
import HoverImage from "./HoverImage";
import TryItOutButton from "./buttons/TryItOutButton";

const AboutSection = () => {
    return (
        <Element name="about-section">
        <Box p={8}>
            <Flex
                direction={{ base: "column", md: "row" }}
                align="center"
                justify="center"
            >
                <Box flexShrink={0} mb={{ base: 4, md: 0 }} mr={{ md: 6 }} >
                    <HoverImage image1={youngMichael} image2={youngMichael2} />
                </Box>
                <Box textAlign={{ base: "center", md: "left" }}>
                    <Heading as="h2" size="xl" mb={4}>
                        Hi, I'm Michael!
                    </Heading>
                    <Text fontSize="lg" maxWidth={"400px"}>
                        A former Montessori student and an assistant
                        Montessori guide during college, now on a mission to give as many children possible the opportunity to thrive in a Montessori classroom.
                        <br /><br />
                        Starting with making Montessori teachers' lives easier by leveraging computer vision of the latest AI models to transcribe handwritten observations into an intuitive record-keeping system. 
                    </Text>
                    <br />
                    <TryItOutButton />
                </Box>
            </Flex>
        </Box>
        </Element>
    );
};

export default AboutSection;
