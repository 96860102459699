import { Box, Image } from "@chakra-ui/react";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";
import { selectSelectedImage } from "../../store/selectors/landingPageSelectors";
import { useRef } from "react";
import { ImageDataModel } from "../../model/imageData";

const MotionBox = motion(Box);

interface LandingPageImageProps {
    src: string;
    top: string;
    left: string;
    index: number;
    data: ImageDataModel[];
    onClick: (
        index: number,
        data: ImageDataModel[],
        ref: React.RefObject<HTMLDivElement>
    ) => void;
}

const LandingPageImage: React.FC<LandingPageImageProps> = ({
    src,
    top,
    left,
    index,
    data,
    onClick,
}) => {
    const selectedImage = useSelector(selectSelectedImage);
    const imageRef = useRef<HTMLDivElement>(null);

    const handleImageClick = () => {
        onClick(index, data, imageRef);
    };

    const isSelected = selectedImage === index;

    return (
        <MotionBox
            ref={imageRef}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            position="absolute"
            top={top}
            left={left}
            onClick={handleImageClick}
            initial={false}
            animate={{
                scale: isSelected ? 1.1 : 1,
                border: isSelected ? "4px solid #777" : "2px solid #777",
                backgroundColor: isSelected ? "#ccc" : "#f5f5f5",
                borderRadius: "11px",
            }}
            cursor={"pointer"}
        >
            <Image
                src={src}
                alt="An image of Montessori observations"
                width="300px"
                height="200px"
                objectFit="cover"
                style={{
                    borderRadius: "8px",
                    boxShadow: isSelected
                        ? "0 4px 8px rgba(0, 0, 0, 0.2)"
                        : "none",
                }}
            />
        </MotionBox>
    );
};

export default LandingPageImage;
