import { Button } from "@chakra-ui/react";
import { Link as ScrollLink } from "react-scroll";

const TryItOutButton = () => {
    return (
        <Button
            colorScheme="green"
            bg="green.400"
            color="white"
            fontWeight="bold"
            _hover={{ bg: "green.500" }}
            as={ScrollLink}
            to="tryitout-section"
            smooth={true}
            duration={500}
            sx={{ cursor: 'pointer' }}
        >
            Try It Out!
        </Button>
    );
};

export default TryItOutButton;
