function PDFEmbed() {
    return (
        <div style={{ height: "100vh" }}>
            <iframe
                src="/DataPrivacyPolicy.pdf"
                width="100%"
                height="100%"
                title="PDF Viewer"
            />
        </div>
    );
}

export default PDFEmbed;
