import { Box, Center, Heading, Text } from "@chakra-ui/react";
import { InlineWidget } from "react-calendly";
import { Element } from "react-scroll";

const CalendlySection = () => {
    return (
        <Element name="calendly-section">
            <Box bg="gray.200" color="black" p={8} pt={16} borderRadius="md">
                <Center>
                    <Heading as="h1" size="lg">
                        {"Interested? Let's explore "}
                        <Text
                            as="span"
                            fontFamily="Zain"
                            fontSize="4xl"
                            display="inline"
                        >
                            Nakia's Notebook
                        </Text>
                        {" together!"}
                    </Heading>
                </Center>

                <InlineWidget
                    url="https://calendly.com/montessoriportal/30min"
                    styles={{
                        width: "100%",
                        height: "750px",
                        overflow: "hidden",
                    }}
                />
            </Box>
        </Element>
    );
};

export default CalendlySection;
