// src/store/index.ts
import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import userReducer from "./store/userSlice";
import landingPageReducer from "./store/landingPageSlice";

const rootReducer = combineReducers({
    user: userReducer,
    landingPage: landingPageReducer
});

const persistConfig = {
    key: "root",
    storage,
    blacklist: ['landingPage']
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [
                    FLUSH,
                    REHYDRATE,
                    PAUSE,
                    PERSIST,
                    PURGE,
                    REGISTER,
                ],
            },
        })
});

export const persistor = persistStore(store);
export type RootState = ReturnType<typeof store.getState>;
