import React from "react";
import { List } from "@chakra-ui/react";
import { motion, AnimatePresence } from "framer-motion";
import UpdateItem from "./LandingPageUpdateItem";
import { ImageDataModel } from "../../model/imageData";

const MotionList = motion(List);

interface UpdatesListProps {
    items: ImageDataModel[];
    initialPosition: { top: number; left: number } | null;
}

const UpdatesList: React.FC<UpdatesListProps> = ({
    items,
    initialPosition,
}) => {
    if (!initialPosition) return null;

    return (
        <MotionList
            key={`${initialPosition.top}-${initialPosition.left}`}
            spacing={4}
            initial={{
                top: initialPosition.top + 85,
                left: initialPosition.left,
                position: "absolute",
                transform: "translate(-50%, -50%)",
            }}
            animate={{
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
            }}
            transition={{ duration: 0.5 }}
            width="90%"
        >
            <AnimatePresence>
                {items.map((item) => (
                    <UpdateItem key={`${item}`} item={item} />
                ))}
            </AnimatePresence>
        </MotionList>
    );
};

export default UpdatesList;
