import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ImageDataModel } from "../model/imageData";
import { Subject } from "../model/subjectWorksData";
import { startingProgress } from "../model/startingProgress";
import { Progress } from "../types/progress";

export interface ProgressModel {
    student: string;
    work: string;
    progress: Progress;
}

interface LandingPageState {
    items: ImageDataModel[];
    selectedImage: number | null;
    imagePosition: { top: number; left: number } | null;
    selectedSubject: Subject;
    progress: ProgressModel[];
    students: string[];
}

const initialState: LandingPageState = {
    items: [],
    selectedImage: null,
    imagePosition: null,
    selectedSubject: "Practical Life",
    progress: startingProgress,
    students: ["Eloise", "Sydney", "Aidan", "Frank"],
};

export const landingPageSlice = createSlice({
    name: "landingPage",
    initialState,
    reducers: {
        setItems: (state, action: PayloadAction<LandingPageState["items"]>) => {
            state.items = action.payload;
        },
        setSelectedImage: (
            state,
            action: PayloadAction<LandingPageState["selectedImage"]>
        ) => {
            state.selectedImage = action.payload;
        },
        setImagePosition: (
            state,
            action: PayloadAction<LandingPageState["imagePosition"]>
        ) => {
            state.imagePosition = action.payload;
        },
        setSelectedSubject: (
            state,
            action: PayloadAction<LandingPageState["selectedSubject"]>
        ) => {
            state.selectedSubject = action.payload;
        },
        removeItem: (state, action: PayloadAction<ImageDataModel>) => {
            state.items = state.items.filter(
                (item) => item.update !== action.payload.update
            );
        },
        addProgress: (state, action: PayloadAction<ProgressModel>) => {
            state.progress = state.progress.filter((prog) => prog.student !== action.payload.student || prog.work !== action.payload.work)
            state.progress.push(action.payload);
        },
        addStudent: (state, action: PayloadAction<string>) => {
            state.students.push(action.payload);
        },
    },
});

export const {
    setItems,
    setSelectedImage,
    setImagePosition,
    setSelectedSubject,
    addProgress,
    removeItem,
} = landingPageSlice.actions;

export default landingPageSlice.reducer;
