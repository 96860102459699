export const dataImage1 = [
    {update: "Sydney is working on SPL #2", subject: "Language"},
    {update: "Aidan mastered Metal Insets", subject: "Language"},
    {update: "Frank was given a lesson on Number Rods", subject: "Math"}
];

export const dataImage2 = [
    {update: "Aidan mastered Knobbed Cylinders", subject: "Sensorial"},
    {update: "Eloise was given a lesson on Button Frame", subject: "Practical Life"},
    {update: "Sydney is working on Spindle Boxes", subject: "Math"}
];

export const dataImage3 = [
    { update: "Frank is working on Weaving", subject: "Practical Life" },
    { update: "Eloise was given a lesson on Continent Map", subject: "Geography" },
    {
        update: "Sydney & Aidan mastered SPL #2",
        subject: "Language",
    },
];


export interface ImageDataModel {
    update: string,
    subject: string
}