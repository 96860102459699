import { Link, Flex, Heading, Button, Box } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import LearnMoreButton from "./buttons/LearnMoreButton";
import AboutButton from "./buttons/AboutButton";

const LandingPageHeader = () => {
    return (
        <Box as="header" bg="teal.500" color="white" p={6}>
            <Flex justify="space-between" align="center">
                <Heading as="h1" size="xl">
                    <Link
                        as={RouterLink}
                        to="/"
                        color="white"
                        fontFamily={"Zain"}
                    >
                        Nakia's Notebook
                    </Link>
                </Heading>
                <Flex gap={4}>
                    <AboutButton />
                    <LearnMoreButton />
                </Flex>
            </Flex>
        </Box>
    );
};

export default LandingPageHeader;
