import React from "react";
import { Box } from "@chakra-ui/react";
import { useImagePosition } from "../../hooks/useImagePosition";
import UpdatesList from "./LandingPageUpdateList";

const MiddleSection: React.FC = () => {
    const { items, initialPosition, containerRef } = useImagePosition();

    return (
        <Box
            ref={containerRef}
            display="flex"
            justifyContent="center"
            alignItems="center"
            p={4}
            position="relative"
            width="100%"
            height="100%"
        >
            <UpdatesList items={items} initialPosition={initialPosition} />
        </Box>
    );
};

export default MiddleSection;
